import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Button from "./button"

const ButtonPhone = () => {
  const query = useStaticQuery(
    graphql`
      query {
        company: airtable(table: { eq: "Basics" }) {
          data {
            Phone
            Opening_hours
            Pronoun
          }
        }
      }
    `
  )

  const pronoun = query.company.data.Pronoun === "I/Me" ? "me" : "us"

  return (

    <Button className={`bg-white flex flex-row space-x-2 items-center justify-center`} type="secondary">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5"
        viewBox="0 0 20 20"
        fill="currentColor"
        alt="phone"
      >
        <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
      </svg>
      <a href={`tel:${query.company.data.Phone}`}>
        {`Call ${pronoun} ${(query.company.data.Opening_hours === "24/7"
          ? `24/7 `
          : "")}at ${query.company.data.Phone}`}
      </a>
    </Button>
  )
}

export default ButtonPhone
