import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const Button = ({ children, onClick, className, type = "primary" }) => {
  const query = useStaticQuery(
    graphql`
      query {
        company: airtable(table: { eq: "Basics" }) {
          data {
            Style
            Palette
            Primary_color
          }
        }
        site: airtable(table: { eq: "Sites" }) {
          data {
            Actions
            Header_background
            Header_type
          }
        }
      }
    `
  )
  const primaryColor = query.company.data.Primary_color
  const style = query.company.data.Style
  const palette = query.company.data.Palette
  const headerBg = query.site.data.Header_background
  const headerType = query.site.data.Header_type

  const colorMap = {
    Blue: {
      primary: "bg-blue-500 text-white",
      secondary: "bg-white text-blue-500",
      outline: "bg-transparent border-2 border-blue-500 text-blue-500",
    },
    Indigo: {
      primary: "bg-indigo-500 text-white",
      secondary: "bg-white text-indigo-500",
      outline: "bg-transparent border-2 border-indigo-500 text-indigo-500",
    },
    Purple: {
      primary: "bg-purple-500 text-white",
      secondary: "bg-white text-purple-500",
      outline: "bg-transparent border-2 border-purple-500 text-purple-500",
    },
    Pink: {
      primary: "bg-pink-500 text-white",
      secondary: "bg-white text-pink-500",
      outline: "bg-transparent border-2 border-pink-500 text-pink-500",
    },
    Red: {
      primary: "bg-red-500 text-white",
      secondary: "bg-white text-red-500",
      outline: "bg-transparent border-2 border-red-500 text-red-500",
    },
    Orange: {
      primary: "bg-yellow-500 text-white",
      secondary: "bg-white text-yellow-500",
      outline: "bg-transparent border-2 border-yellow-500 text-yellow-500",
    },
    Yellow: {
      primary: "bg-yellow-300 text-white",
      secondary: "bg-white text-yellow-300",
      outline: "bg-transparent border-2 border-yellow-300 text-yellow-300",
    },
    Lime: {
      primary: "bg-lime-500 text-white",
      secondary: "bg-white text-lime-500",
      outline: "bg-transparent border-2 border-lime-500 text-lime-500",
    },
    Green: {
      primary: "bg-green-500 text-white",
      secondary: "bg-white text-green-500",
      outline: "bg-transparent border-2 border-green-500 text-green-500",
    },
    Black: {
      primary: "bg-black text-white",
      secondary: "bg-white text-black",
      outline: "bg-transparent border-2 border-black text-black",
    },
  }

  //Sets default color if none is specified
  const color = colorMap[palette] || "bg-blue-500"

  const radiusMap = {
    Simple: "rounded-xl",
    Playful: "rounded-full",
    Elegant: "rounded-none",
    Smart: "rounded-full",
    Hacker: "rounded-none",
    Bold: "rounded-xl",
    Modern: "rounded-none",
  }

  const isSecondary = type === "secondary"
  const isOutline = type === "outline"
  const isCustom = palette === "Custom"
  const darkBg = headerBg === "Dark" || headerType === "Cover"

  return (
    <button

    style={{background:isCustom ? isOutline ? "white" :isSecondary ? "white" : primaryColor : "",
            color:isCustom ? isOutline ? primaryColor :isSecondary ? primaryColor : "white" : "",
            borderColor:isCustom ? isOutline ? primaryColor : "" : "",
            borderWidth:isCustom ? isOutline ? "2px" : "" : "",
           }
  
  }
    
      className={`${className} ${
          isCustom
          ? ""
          : isOutline
          ? color.outline
          : isSecondary
          ? color.secondary
          : `${color.primary} ${
              darkBg && palette === "Black" ? "filter invert" : ""
            }`
      } ${
        radiusMap[style]
      } py-3 px-8 inline-block text-lg font-bold focus:outline-none`}

      onClick={onClick}
    >
      {children}
    </button>
  )
}

export default Button
